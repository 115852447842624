<template>
  <div>
    <v-snackbar v-model="snackbar.show" top right color="success" timeout="2000">
      <v-icon class="mr-2">mdi-check</v-icon>
      <span> Profile Updated! </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-toolbar flat color="#00439B" class="border-bottom">
          <v-toolbar-title class="text-white"> Update Profile</v-toolbar-title>
        </v-toolbar>
        <v-form @submit.prevent="updateInfo"  ref="form" lazy-validation>
          <v-card-text>
           
              <v-row dense>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="form.first_name"
                    :rules="rules.first"
                    label="First name"
                    required
                    :disabled="overlay"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="form.middle_name"
                    label="Middle name"
                    :disabled="overlay"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="form.last_name"
                    :rules="rules.last"
                    label="Last name"
                    required
                    :disabled="overlay"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="form.phone_number"
                    :rules="rules.phone"
                    label="Contact Number"
                    required
                    :disabled="overlay"
                    @keyup="accept"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <Countries v-bind:disabled="overlay" v-model="form.country" v-bind:country="form.country"> </Countries>
                </v-col>
                <v-col cols="12" md="8" sm="12">
                  <v-text-field
                    v-model="form.address"
                    :rules="rules.address"
                    label="Address"
                    required
                    :disabled="overlay"
                    hint="town city"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <Timezone v-model="form.user_company.timezone_id" v-bind:id="form.user_company.timezone_id"> </Timezone>
                </v-col>
              </v-row>
        
          </v-card-text>
          <v-card-actions class="border-top" >
            <div class="w-100 text-right">
              <v-btn text class="text-secondary " @click="closeInfo" :disabled="overlay"> Close </v-btn>
              <v-btn color="#0A009B" text type="submit" :loading="overlay"> update </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Timezone from '@/components/Timezone'
import Countries from '@/components/Countries'
export default {
  components : {
    Timezone,
    Countries
  },
  props: ["user", 'dialog'],
  created() {
    this.populateInput();
  },

  data: () => ({

    absolute: true,
    overlay: false,
    date: new Date().toISOString().substr(0, 10),
    modal: false,
    form: {
      first_name: null,
      middle_name: null,
      last_name: null,
      phone_number: null,
      address : null,
      country : null,
      user_company : {
         timezone_id : null
      }
     
    },
    rules: {
      first: [(value) => !!value || "First name is required"],
      last: [ (value) => !!value || "Last name is required" ],
      phone: [],
      password: [(value) => !!value || "Password is required"],
      address : [(value) => !!value || "Please provide your address"],
      country : [(value) => !!value || "please provde your country" ]
    },
    snackbar: {
      show : false
    },
    countries : [],
  }),
  computed : {
    show: {
      get() {
        this.populateInput()
        
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    sortFunc: function (){
      return this.countries.slice().sort(function(a, b){
        return (a > b) ? 1 : -1;
      });
    }
  },

  methods: {
    sortParent(item)
      {
        return item.slice().sort(function(a, b) {
          return a.position - b.position
        })
      },
     accept()
    {
      if(this.form.phone_number)
      {
        let x = this.form.phone_number.replace(/\D/g , '').match(/(\d{0,4})(\d{0,3})(\d{0,4})/)
        this.form.phone_number = !x[2] ? x[1] :  x[1] + ' ' + x[2] + ( x[3] ? ' ' + x[3].substring(0, 4) : '');
      }
    },
    updateInfo() {
      
      if (this.$refs.form.validate()) {
       this.overlay = true
        this.form.phone_number = this.form.phone_number ? this.form.phone_number.replace(/\s/g, '') : ''
        this.axios
          .put("/userslist/" + this.user.id, this.form)
          .then((response) => {
            if (response.data[1] == "success") {
              this.snackbar.show = true;
            }
          })
          .finally(() => {
            this.show = false
            this.overlay = false
            this.$emit("refresh", "u");
          });
      }
    },
    closeInfo() {
      this.show = false;
    },
    populateInput() {
     
      this.countries = this.$countries.names()

      if(this.user)
      {
  
        this.form.first_name = this.user.first_name ? this.user.first_name : null
        this.form.middle_name = this.user.middle_name ? this.user.middle_name : null
        this.form.last_name = this.user.last_name ? this.user.last_name : null
        this.form.phone_number = this.user.phone_number ? this.user.phone_number : null
        this.form.country = this.user.country ? this.user.country : null
        this.form.address = this.user.address ? this.user.address : null
        this.form.user_company.timezone_id = this.user.default_user_company.timezone_id ? this.user.default_user_company.timezone_id : null
        this.form.user_company.id = this.user.default_user_company.id

        
      }
      
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>



<style scoped>
#btn {
  text-decoration: none;
  text-transform: none;
}
</style>